import React from "react"

export const languages = {
  fr: {
    locale: "fr",
    discoverMore: "Découvrez-en plus",
    all: "Tout",
    search: "Recherche",
    results: "Résultats",
    noResults: "Aucun résultat",
    download: "Télécharger",
    latestArticles: "Dernières nouvelles",
    formInputTexts: {
      fullName: "Nom complet",
      company: "Société",
      address: "Rue",
      city: "Ville",
      province: "Province",
      zipCode: "Code postal",
      phone: "Téléphone",
      message: "Message",
    },
    sendRequestForm: "Soumettre",
    loading: "Chargement",
    requestSent: "Demande envoyée",
  },
  de: {
    locale: "de",
    discoverMore: "Mehr entdecken",
    all: "Alle",
    search: "Suchen",
    results: "Ergebnisse",
    noResults: "Keine Ergebnisse",
    download: "Herunterladen",
    latestArticles: "Neueste Nachrichten",
    formInputTexts: {
      fullName: "Vollständiger Name",
      company: "Unternehmen",
      address: "Straße",
      city: "Stadt",
      province: "Provinz",
      zipCode: "POSTCODE",
      phone: "Telefon",
      message: "Nachricht",
    },
    sendRequestForm: "Senden Sie",
    loading: "Loading",
    requestSent: "Request sent",
  },
  en: {
    locale: "en",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    formInputTexts: {
      fullName: "Full name",
      company: "Company",
      address: "Street",
      city: "City",
      province: "Province",
      zipCode: "Zip Code",
      phone: "Phone",
      message: "Message",
    },
    sendRequestForm: "Submit",
    loading: "Loading",
    requestSent: "Request sent",
  },
  "en-us": {
    locale: "en-US",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    formInputTexts: {
      fullName: "Full name",
      company: "Company",
      address: "Street",
      city: "City",
      province: "Province",
      zipCode: "Zip Code",
      phone: "Phone",
      message: "Message",
    },
    sendRequestForm: "Submit",
    loading: "Loading",
    requestSent: "Request sent",
  },
  it: {
    locale: "it",
    discoverMore: "Scopri di più",
    all: "Tutti",
    search: "Cerca",
    results: "risultati",
    noResults: "Nessun risultato",
    download: "Scarica",
    latestArticles: "Ultime news",
    sendRequestForm: "Invia",
    loading: "Caricamento",
    requestSent: "Richiesta inviata!",
    formInputTexts: {
      fullName: "Nome completo",
      company: "Azienda",
      address: "Via",
      city: "Città",
      province: "Provincia",
      zipCode: "CAP",
      phone: "Telefono",
      message: "Messaggio",
    },
  },
}

export const i18nContext = React.createContext(languages.it)
