import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { getHomePath } from "../utils/path"
import { Link } from "./link"
import { MagicLink } from "../utils/magicLink"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import Logo from "../images/logo.png"
import { Image } from "@theme-ui/components"
import { ChevronDown, ChevronUp, Menu, X } from "react-feather"
import { InboundLink } from "./link"
import { motion, AnimatePresence } from "framer-motion"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import LanguageSwitcher from "./languageSwitcher"

const MobileNav = ({ theme }) => {
  const primary = getColor(themeUiTheme, "primary")
  const dark = getColor(themeUiTheme, "dark")
  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const useMenuData = useMenu()
  const menu = useMenuData

  const productFamily = useMenuData.allDatoCmsProductFamily
  const productCategory = useMenuData.allDatoCmsProductCategory

  menu.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  const sideVariants = {
    closed: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
  }

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box>
        <Container
          sx={{
            backgroundColor: "light",
            position: "relative",
            paddingY: [2, 3],
          }}
        >
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <InboundLink
                to={getHomePath(locale)}
                sx={{
                  display: "block",
                  position: "relative",
                  img: {
                    height: ["50px"],
                  },
                }}
              >
                <Image src={Logo} alt="Logo" />
              </InboundLink>
            </Box>
            <Box onClick={() => setShow(!show)}>{!show ? <Menu /> : <X />}</Box>
          </Flex>
        </Container>
      </Box>

      <motion.div
        initial={{
          position: "fixed",
          backgroundColor: dark,
          width: "100%",
          top: 0,
          left: 0,
          zIndex: 1000,
          overflowY: "auto",
          height: show ? "100%" : 0,
        }}
        animate={{
          height: show ? "100%" : 0,
          transition: {},
        }}
        transition={{ type: "tween", stiffness: 50, delayChildren: 1 }}
      >
        <Container sx={{ backgroundColor: "light", paddingY: [2, 3] }}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <InboundLink
                to={getHomePath(locale)}
                sx={{
                  img: {
                    height: ["50px"],
                  },
                }}
              >
                <Image src={Logo} alt="Logo" />
              </InboundLink>
            </Box>
            <Box onClick={() => setShow(!show)}>
              <X />
            </Box>
          </Flex>
        </Container>
        <motion.div
          className="container"
          initial="closed"
          animate="open"
          exit="closed"
          variants={sideVariants}
        >
          <Flex
            sx={{
              flexDirection: "column",
              padding: 0,
              paddingY: 3,
              margin: 0,
              listStyle: "none",
            }}
            as="ul"
          >
            {menu.map((item, index) => (
              <TextComponent
                item={item}
                showNav={show}
                locale={locale}
                key={item.id}
                theme={theme}
                index={index}
                productFamily={productFamily}
                productCategory={productCategory}
              />
            ))}
            <Flex
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  mt: [4],
                  mx: [2],
                }}
              >
                <LanguageSwitcher />
              </Box>
            </Flex>
          </Flex>
        </motion.div>
      </motion.div>
    </Box>
  )
}

const TextComponent = ({
  item,
  locale,
  showNav,
  theme,
  productFamily,
  productCategory,
  index,
}) => {
  const [show, setShow] = useState(false)
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        position: "relative",
        fontWeight: "normal",
        color: light,
        a: {
          textDecoration: "none",
        },
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a,& > div a": {
          color: "light",
          paddingX: 3,
          paddingY: [2],
          display: "block",
        },
        "a.active": {
          color: "primary",
          textDecoration: "none",
        },
      }}
      onClick={() => setShow(!show)}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: showNav ? 1 : 0,
          transition: {
            opacity: {
              delay: showNav ? index * 0.09 : 0,
            },
          },
        }}
      >
        {item.link ? (
          <Flex
            sx={{
              cursor: "default",
              paddingY: [2, 2],
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MagicLink
              item={item.link}
              locale={locale}
              productFamily={productFamily}
              productCategory={productCategory}
            />
          </Flex>
        ) : (
          <Flex
            sx={{
              cursor: "default",
              paddingX: 3,
              paddingY: [3, 2],
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>{item.anchor}</Box>
            <Box>
              {!show ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
            </Box>
          </Flex>
        )}
        {item.treeChildren.length > 0 && (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: show ? "auto" : 0,
              opacity: show ? 1 : 0,
            }}
            transition={{ type: "tween", stiffness: 50 }}
          >
            <Box
              as="ul"
              sx={{
                listStyle: "none",
                px: [3, 4],
                m: [0, 0, 0, 0],
                backgroundColor: "darkBackground",
                position: "relative",
                width: "100%",
              }}
            >
              {item.treeChildren.map(item =>
                item.anchor ? (
                  <Box
                    as="li"
                    key={item.id}
                    sx={{
                      mt: 1,
                      mb: 1,
                      a: {
                        textDecoration: "none",
                        color: "light",
                      },
                    }}
                  >
                    {item.link ? (
                      <MagicLink
                        item={item.link}
                        locale={locale}
                        productFamily={productFamily}
                        productCategory={productCategory}
                      />
                    ) : (
                      <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                    )}
                  </Box>
                ) : null
              )}
            </Box>
          </motion.div>
        )}
      </motion.div>
    </Box>
  )
}

export default MobileNav
