import React, { useState, useEffect, useRef, useMeasure } from "react"
import { Box, Container, Flex, Image, Grid } from "@theme-ui/components"
import { useMenu } from "../hooks/useMenu"
import { getHomePath, getSearchPath } from "../utils/path"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import LanguageSwitcher from "./languageSwitcher"
import { debounce } from "lodash"
import Logo from "../images/logo.png"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { motion, AnimatePresence } from "framer-motion"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Nav = () => {
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()
  const light = getColor(themeUiTheme, "light")
  const primary = getColor(themeUiTheme, "primary")

  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [active, setActive] = useState(false)
  const [currentTheme, setCurrentTheme] = useState("light")

  menu.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  const debouncedHandleMouseEnterMenu = debounce(index => {
    setActive(true)
    setShow(true)
    setSelectedItem(index)
    setCurrentTheme("dark")
  }, 100)

  const handlOnMouseLeaveMenu = () => {
    console.log("---mouse leave")
    setActive(false)
    setShow(false)
    setCurrentTheme("light")
    debouncedHandleMouseEnterMenu.cancel()
  }

  const megamenuWrapperGridVariants = {
    open: {
      opacity: 1,
      visibility: "visible",
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      visibility: "hidden",
      opacity: 0,
      overflow: "hidden",
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const megamenuWrapperGridItemsVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <Box
      as="nav"
      sx={{
        backgroundColor: active ? "white" : "transparent",
        "-webkit-transition": "background-color .1s linear",
        "-ms-transition": "background-color .1s linear",
        transition: "background-color .1s linear",
        position: "relative",
      }}
      onMouseLeave={() => {
        handlOnMouseLeaveMenu()
      }}
    >
      <Grid
        columns={["154px 1fr"]}
        sx={{
          borderBottom: active && "1px solid",
          borderColor: active && "lightGray",
          zIndex: 50,
        }}
      >
        <InboundLink to={getHomePath(locale)}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              px: [3, 3, 3],
              py: [3],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "light",
              img: { height: "auto", maxHeight: "108px" },
            }}
          >
            <Image src={Logo} alt="Logo" />
          </Box>
        </InboundLink>
        <Grid columns={[1]}>
          <Flex
            sx={{
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <Box
              sx={{
                mt: [4],
                mx: [2],
              }}
            >
              <LanguageSwitcher dark={active} />
            </Box>
          </Flex>
          <Flex
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 0,
              margin: 0,
              listStyle: "none",
              pb: [2],
            }}
            as="ul"
          >
            {menu.map((item, index) => (
              <>
                <TextComponent
                  item={item}
                  locale={locale}
                  id={item.id}
                  key={item.id}
                  setShow={setShow}
                  index={index}
                  setSelectedItem={setSelectedItem}
                  setActive={setActive}
                  currentTheme={currentTheme}
                  setCurrentTheme={setCurrentTheme}
                  debouncedHandleMouseEnterMenu={debouncedHandleMouseEnterMenu}
                />
                {/* {index == selectedItem && (
                  <motion.div
                    className="underline"
                    style={{
                      position: "absolute",
                      bottom: "-1px",
                      left: 0,
                      right: 0,
                      height: "1px",
                      background: primary,
                    }}
                  />
                )} */}
              </>
            ))}
          </Flex>
        </Grid>
      </Grid>
      {menu.map(
        (item, index) =>
          item.treeChildren.length > 0 && (
            <motion.div
              initial={false}
              style={{
                display: "block",
                position: "absolute",
                top: "100%",
                width: "100%",
                backgroundColor: light,
              }}
              animate={show && selectedItem === index ? "open" : "closed"}
              variants={megamenuWrapperGridVariants}
              exit={{ opacity: 0 }}
              onMouseOver={() => {
                setShow(true)
                setCurrentTheme("dark")
                setActive(true)
              }}
            >
              <Container sx={{ position: "relative", width: "100%" }}>
                <motion.div variants={megamenuWrapperGridItemsVariants}>
                  <Grid
                    columns={[4, 6]}
                    sx={{
                      display: "grid",
                      listStyle: "none",
                      padding: [3],
                      margin: [0],
                      width: "100%",
                    }}
                  >
                    {item.treeChildren.map(
                      item =>
                        item.anchor && (
                          <Box sx={{ py: [1] }} key={item.id}>
                            {item.link ? (
                              <Box
                                sx={{
                                  height: "100%",
                                  mb: 2,
                                  ".gatsby-image-wrapper": {
                                    width: "100%",
                                    height: "auto",
                                    pointerEvents: "none",
                                  },
                                  a: {
                                    textTransform: "uppercase",
                                    textDecoration: "none",
                                    fontWeight: "600",
                                    height: "100%",
                                    display: "grid",
                                    fontSize: [2],
                                    lineHeight: "1",
                                    gridTemplateRows: ["65% 35%"],
                                    borderBottom: "2px solid",
                                    borderColor: "dark",
                                  },
                                }}
                              >
                                <MagicLink
                                  sx={{
                                    color: "text",
                                    display: "block",
                                  }}
                                  item={item.link}
                                  locale={locale}
                                >
                                  <GatsbyImage
                                    image={
                                      item.link.link.thumbnailImage
                                        .gatsbyImageData
                                    }
                                    alt={
                                      item.link.link.thumbnailImage
                                        .gatsbyImageData
                                    }
                                  />
                                  <Flex
                                    sx={{
                                      py: [3],
                                      alignItems: "start",
                                      hyphens: item.locale === "de" && "auto",
                                    }}
                                  >
                                    {item.anchor}
                                  </Flex>
                                </MagicLink>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  cursor: "default",
                                  hyphens: item.locale === "de" && "auto",
                                }}
                              >
                                {item.anchor}
                              </Box>
                            )}
                          </Box>
                        )
                    )}
                  </Grid>
                </motion.div>
              </Container>
            </motion.div>
          )
      )}
    </Box>
  )
}

const TextComponent = ({
  item,
  locale,
  currentTheme,
  index,
  debouncedHandleMouseEnterMenu,
}) => {
  const [show, setShow] = useState(null)
  const componentTheme = currentTheme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        height: "100%",
        position: "relative",
        "> a": {
          textTransform: "uppercase",
          color: light,
          textDecoration: "none",
        },
        "a:hover": {
          color: "primary",
        },
        marginX: [3],
        paddingX: [2],
      }}
      onMouseOver={() => {
        setShow(index)
        debouncedHandleMouseEnterMenu(index)
      }}
    >
      {item.link ? (
        <MagicLink
          item={item.link}
          locale={locale}
          sx={{ fontSize: locale === "de" && [1] }}
        />
      ) : (
        <Box
          sx={{
            cursor: "default",
            color: light,
            textTransform: "uppercase",
            fontSize: locale === "de" && [1],
          }}
        >
          {item.anchor}
        </Box>
      )}
    </Box>
  )
}

export default Nav
