/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Grid } from "@theme-ui/components"
import { i18nContext, languages } from "../context/i18nContext"
import Header from "./header"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import Hreflang from "./hreflang"
import Footer from "./footer"
import Canonical from "./canonical"

import useWindowDimensions from "../utils/windowSizes"

const Layout = ({ children, locale, i18nPaths, home = false }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      datoCmsSite: datoCmsSite {
        locale
      }
      gatsbySite: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <Box>
      <i18nContext.Provider
        value={languages[locale] || languages[data.datoCmsSite.locale]}
      >
        <LanguageSwitcherContext.Provider
          value={{ activeLocale: locale, paths: i18nPaths || [] }}
        >
          <Hreflang
            paths={i18nPaths}
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
          />
          <Canonical
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
            paths={i18nPaths}
          />
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "100vh",
            }}
          >
            <Header />
            <Box
              as="main"
              sx={{
                pt: home ? 0 : 0,
                backgroundColor: !home && "backgroundColor",
                zIndex: 0,
              }}
            >
              {children}
            </Box>
            <Footer />
          </Flex>
        </LanguageSwitcherContext.Provider>
      </i18nContext.Provider>
    </Box>
  )
}

export default Layout
