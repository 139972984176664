import React, { useState, useEffect } from "react"
import { Box, Container, Flex, Grid, Text, Image } from "@theme-ui/components"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Linkedin,
  ArrowUp,
} from "react-feather"
import LongArrowRight from "./LongArrowRight"
import * as Scroll from "react-scroll"
import LinkedinWhite from "../images/linkedin-white.png"
import LinkedinBlack from "../images/linkedin-black.png"

const Footer = () => {
  const footer = useFooter()
  const social = useSocial()

  let scroll = Scroll.animateScroll

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (
      typeof window !== "undefined" &&
      !showScroll &&
      window.pageYOffset > 400
    ) {
      setShowScroll(true)
    } else if (
      typeof window !== "undefined" &&
      showScroll &&
      window.pageYOffset <= 400
    ) {
      setShowScroll(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined")
      window.addEventListener("scroll", checkScrollTop)
  }, [])

  footer.map(footerItem => {
    footerItem.treeChildren.sort((a, b) => a.position - b.position)
    footerItem.treeChildren.map(footerItem => {
      if (footerItem.treeChildren.length > 0) {
        footerItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box
      id="footer"
      as="footer"
      sx={{
        backgroundColor: "primary",
        pt: 5,
        a: { color: "dark" },
        zIndex: 2,
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Box
              variant="h4"
              sx={{
                zIndex:"9",
                color: "light",
                cursor:"pointer",
                m: 0,
                position: ["fixed"],
                bottom: [3, 4, 5, 6],
                right: [3, 4, 5, 6],
                alignItems: "center",
                justifyContent: "center",
                width: "42px",
                height: "42px",
                backgroundColor: "dark",
                display: showScroll ? ["none","flex"] : ["none","none"],
              }}
            >
              <ArrowUp onClick={() => scrollToTop()} />
            </Box>
            <Container>
              <Grid
                columns={[2, footer.length]}
                gap={[2, 10]}
                sx={{ alignItems: "center", position: "relative" }}
              >
                {footer.map(
                  (column, index) =>
                    index !== 0 &&
                    column.treeChildren.length > 0 && (
                      <List key={column.id}>
                        {column.treeChildren.map(link => (
                          <Item>
                            <Text
                              sx={{
                                fontWeight: "bold",
                                mb: 2,
                                display: "block",
                              }}
                            >
                              {link.link ? (
                                <MagicLink
                                  item={link.link}
                                  sx={{ color: "light" }}
                                >
                                  <Flex sx={{ alignItems: "center" }}>
                                    {index === 0 && (
                                      <LongArrowRight color="light" />
                                    )}
                                    <Box
                                      sx={{
                                        ml: [0, index === 1 ? 0 : 3],
                                        color: index === 0 && "light",
                                      }}
                                    >
                                      {link.anchor}
                                    </Box>
                                  </Flex>
                                </MagicLink>
                              ) : (
                                link.anchor
                              )}
                            </Text>
                            {link.treeChildren.length > 0 && (
                              <List key={link.id}>
                                {link.treeChildren.map(link => (
                                  <Item>
                                    {link.link ? (
                                      <MagicLink item={link.link}>
                                        <Box sx={{ ml: 3, color: "dark" }}>
                                          {link.anchor}
                                        </Box>
                                      </MagicLink>
                                    ) : (
                                      link.anchor
                                    )}
                                  </Item>
                                ))}
                              </List>
                            )}
                          </Item>
                        ))}
                      </List>
                    )
                )}
                <Box></Box>
              </Grid>
              <Box sx={{  }}>
                <Box sx={{ fontWeight: "400", fontSize:[0] }}>
                  © S.O.M, 2022 | S.O.M. DI REBOLDI DARIO ED ENZO SRL | Sede legale; Via Veneto, 2, 25069 Villa Carcina BS  | Partita IVA; 00555800986 | REA; BS112858 | Capitale sociale : 100.000 € 
                </Box>
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: ["column", "row"],
        margin: 0,
        padding: 0,
        mb: [4],
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box
      {...props}
      as="li"
      sx={{
        mr: [4, 6],
        textTransform: "lowercase",
        "&:last-child": { mb: 0 },
        "a.active": {
          color: "light",
          textDecoration: "underline",
        },
      }}
    />
  )
}

export default Footer
