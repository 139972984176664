import { Box , Container} from "@theme-ui/components"
import React, { useEffect, useState } from "react"
import Nav from "./nav"
import MobileNav from "./mobileNav"

const Header = () => {
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
      setScrolling(e.target.documentElement.scrollTop > scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return (
    <Box
      as="header"
      sx={{
        top: 0,
        position: ["fixed","absolute"],
        width: "100%",
        zIndex: "9999",
      }}
    >
      
      <Box sx={{ display: ["none", "none", "none", "block"] }}>
        <Nav  />
      </Box>
      <Box sx={{ display: ["block", "block", "block", "none"] }}>
        <MobileNav theme={"light"} />
      </Box>
    </Box>
  )
}

export default Header
