import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import RightLongArrow from "../assets/images/long-arrow-right.svg"

const LongArrowRight = ({ color }) => (
  <Flex
    sx={{
      border: "2px solid",
      minWidth: "55px",
      maxWidth: "55px",
      minHeight: "55px",
      maxHeight: "55px",
      borderColor: color ? color : "dark",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      svg: {
        "#Group-3": {
          fill: color,
        },
      },
    }}
  >
    <Box
      sx={{
        svg: {
          width: "36px",
        },
      }}
    >
      <RightLongArrow />
    </Box>
  </Flex>
)

export default LongArrowRight
